let lastFootnote = 0;
const footnoteMap = new Map(); // href -> nr

for (const a of document.querySelectorAll(`a[href^="http"]`)) {
  const href = a.href;
  let footnote = footnoteMap.get(href);
  if (typeof footnote === "undefined") {
    lastFootnote = footnote = lastFootnote + 1;
    footnoteMap.set(href, footnote);
  }
  // a.dataset.footnote = String(footnote);
}

/*
const footnoteContainer = document.createElement("template");
footnoteContainer.innerHTML = `<section class="footnotes">
  <h2>Links</h2>
  <ul>
    ${Array.from(
      footnoteMap,
      ([href, id]) =>
        `<li><sup>[${id}]</sup> <a href="${href}">${href}</a></li>`
    ).join("")}
  </ul>
</section>`;

document.querySelector("main").append(footnoteContainer.content);
*/
